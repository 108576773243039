<template>
  <el-dialog
    v-model="dialogVisible"
    :title="`${cusProps.action === 'create' ? '新增' : '編輯'}活動`"
    width="880px"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="discount-form"
      :rules="rules"
    >
      <el-form-item class="form-item" prop="name">
        <template #label>
          <span class="label">活動名稱</span>
        </template>
        <el-input v-model="ruleForm.name" placeholder="請輸入活動名稱" />
      </el-form-item>
      <el-form-item class="form-item" prop="remark" style="grid-area: 1/2/4/3">
        <template #label>
          <span class="label">活動說明</span>
        </template>
        <el-input v-model="ruleForm.remark" type="textarea" :rows="11" placeholder="請輸入活動方式" resize="none" />
      </el-form-item>
      <!-- 門市 -->
      <el-form-item class="form-item" prop="available_shop_type">
        <template #label>
          <span class="label">門市</span>
          <el-radio-group v-model="ruleForm.available_shop_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in available_shop_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-select
          v-if="ruleForm.available_shop_type === 2"
          v-model="ruleForm.available_shop_value_array"
          multiple
          placeholder="請選擇區域"
          style="width: 100%"
        >
          <el-option v-for="item in area_options" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
        <upload-file
          v-if="ruleForm.available_shop_type === 3"
          v-model:file_url="ruleForm.available_shop_file_url"
          v-model:file_name="ruleForm.available_shop_file_name"
        ></upload-file>
      </el-form-item>
      <!-- 日期 -->
      <el-form-item class="form-item" prop="time_period_type">
        <template #label>
          <span class="label">活動日期</span>
          <el-radio-group
            v-model="ruleForm.time_period_type"
            style="margin-left: 15px; transform: translateY(1px)"
            @change="clearItemValidate(ruleFormRef, 'time_period_type')"
          >
            <el-radio v-for="item in time_period_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-date-picker
          v-if="ruleForm.time_period_type === 2"
          v-model="datetimeRange"
          type="datetimerange"
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          format="YYYY/MM/DD HH:mm"
          value-format="YYYY/MM/DD HH:mm"
          :default-time="defaultTime"
        />
      </el-form-item>
      <!-- 折扣條件 -->
      <div class="title" style="grid-area: 4/1/5/3">
        <span>折扣條件</span>
        <el-divider />
      </div>
      <el-form-item class="form-item" prop="event_rule_source_platform">
        <template #label>
          <span class="label">訂單來源</span>
        </template>
        <el-select
          v-model="ruleForm.event_rule_source_platform"
          multiple
          placeholder="請選擇來源"
          style="width: 100%"
          @change="handleSelectSource"
        >
          <el-option
            v-if="!checkSelectAll(ruleForm.event_rule_source_platform, source_options)"
            label="全選"
            value="all"
          ></el-option>
          <el-option v-else label="取消全選" value="none"></el-option>
          <el-option v-for="item in source_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" prop="event_rule_take_away_method">
        <template #label>
          <span class="label">訂單類型</span>
        </template>
        <el-select
          v-model="ruleForm.event_rule_take_away_method"
          multiple
          placeholder="請選擇類型"
          style="width: 100%"
          @change="handleSelectMethod"
        >
          <el-option
            v-if="!checkSelectAll(ruleForm.event_rule_take_away_method, take_away_options)"
            label="全選"
            value="all"
          ></el-option>
          <el-option v-else label="取消全選" value="none"></el-option>
          <el-option v-for="item in take_away_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <!-- 折扣商品part -->
      <el-form-item class="form-item" prop="event_rule_category">
        <template #label>
          <span class="label">折扣商品</span>
          <el-radio-group v-model="ruleForm.event_rule_category" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio
              v-for="item in event_rule_category_options"
              :key="item.label"
              :label="item.value"
              style="height: 20px"
              @click.prevent="handleChangeCategory(item.value)"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-select
          v-if="ruleForm.event_rule_category === 2"
          v-model="selectEventRuleId"
          placeholder="請選擇類別"
          style="width: 100%"
          clearable
          filterable
          @change="handleSelectProduct($event, 'category')"
        >
          <el-option v-for="item in getCategoryList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <el-cascader
          v-if="ruleForm.event_rule_category === 3"
          v-model="selectEventRuleId"
          :options="getProductTree"
          :show-all-levels="false"
          :props="productTreeProps"
          placeholder="請選擇或輸入品項名稱"
          clearable
          filterable
          style="width: 100%; transform: translateY(-1px)"
          @change="handleSelectProduct($event, 'product')"
        />
      </el-form-item>
      <el-form-item v-if="ruleForm.event_rule_category !== 1" class="form-item">
        <template #label>
          <span class="label">容量</span>
        </template>
        <el-select
          v-model="selectEventRuleSize"
          placeholder="請選擇容量"
          style="width: 100%"
          :disabled="!selectEventRuleId"
        >
          <el-option label="不指定" value="" />
          <el-option v-for="item in getProductSize" :key="item" :label="mapOptionsLabel(item)" :value="item" />
        </el-select>
      </el-form-item>
      <!-- 類別和商品的列表 -->
      <div v-if="ruleForm.event_rule_category !== 1" class="event-rule-list" style="grid-area: 7/1/8/3">
        <el-button
          style="width: 100%; margin-bottom: 8px"
          :disabled="!selectEventRuleId || ruleForm.event_rule_value?.length === 5"
          @click="handleAddEventProduct('category')"
        >
          新增{{ ruleForm.event_rule_category === 2 ? '類別' : '商品' }}
          <el-icon style="margin-left: 5px"><CirclePlus /></el-icon>
        </el-button>
        <el-table
          :data="ruleForm.event_rule_value"
          style="width: 100%"
          :empty-text="`請按「新增${ruleForm.event_rule_category === 2 ? '類別' : '商品'}」加入項目，最多五項`"
          class="event-product-table"
          header-cell-class-name="product-table-header"
        >
          <el-table-column prop="id" :label="ruleForm.event_rule_category === 2 ? '類別' : '商品'">
            <template #default="scope">
              <span v-if="ruleForm.event_rule_category === 2">{{ mapCategoryLabel(scope.row.id) }}</span>
              <span v-else>{{ mapProductLabel(scope.row.id) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="size" label="容量" width="120">
            <template #default="scope">
              <span>{{ mapOptionsLabel(scope.row.size) || '不指定' }}</span>
            </template>
          </el-table-column>
          <el-table-column width="80">
            <template #default="scope">
              <el-button link type="danger" size="small" @click.prevent="handleDeleteEventProduct(scope.$index, '1')">
                刪除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 折扣方式 -->
      <div class="title" style="grid-area: 8/1/9/3">
        <span>折扣方式</span>
        <el-divider />
      </div>
      <div style="grid-area: 9/1/10/3">
        <!-- 折扣類型 -->
        <el-form-item style="margin-bottom: 30px">
          <el-radio-group v-model="ruleForm.event_rule_type" size="large">
            <el-radio-button v-for="item in event_rule_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- 數量或金額 -->
        <el-form-item
          v-if="ruleForm.event_rule_type === 1 || ruleForm.event_rule_type === 3"
          class="form-item"
          prop="event_rule_amount"
        >
          <template #label>
            <span class="label">{{ ruleForm.event_rule_type === 1 ? '數量' : '金額' }}</span>
          </template>
          <el-input
            v-model="ruleForm.event_rule_amount"
            :placeholder="`請輸入${ruleForm.event_rule_type === 1 ? '數量' : '金額'}`"
            style="width: calc(50% - 12.5px)"
            type="number"
          >
            <template #append>{{ ruleForm.event_rule_type === 1 ? '件' : '元' }}</template>
          </el-input>
        </el-form-item>
        <!-- 折扣範圍 -->
        <el-form-item v-if="ruleForm.event_rule_type === 1 || ruleForm.event_rule_type === 2" class="form-item">
          <template #label>
            <span class="label">折扣範圍</span>
          </template>
          <el-radio-group v-model="ruleForm.event_range">
            <el-radio-button v-for="item in event_range_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- 折扣內容 -->
        <el-form-item class="form-item" prop="event_type">
          <template #label>
            <span class="label">內容</span>
            <el-radio-group
              v-if="(ruleForm.event_rule_type === 1 || ruleForm.event_rule_type === 2) && ruleForm.event_range === 2"
              v-model="ruleForm.event_type"
              style="margin-left: 15px; transform: translateY(1px)"
            >
              <el-radio v-for="item in event_type_options" :key="item.label" :label="item.value">
                {{ item.label }}
              </el-radio>
            </el-radio-group>
            <el-radio-group v-else v-model="ruleForm.event_type" style="margin-left: 15px; transform: translateY(1px)">
              <el-radio v-for="item in event_type_options_giveaway" :key="item.label" :label="item.value">
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </template>
          <el-input
            v-if="ruleForm.event_type === 1"
            v-model="ruleForm.event_value"
            placeholder="請輸入折數（個位數）"
            style="width: calc(50% - 12.5px)"
            type="number"
          >
            <template #append>折</template>
          </el-input>
          <el-input
            v-if="ruleForm.event_type === 2"
            v-model="ruleForm.event_value"
            placeholder="請輸入折抵金額"
            style="width: calc(50% - 12.5px)"
            type="number"
          >
            <template #prepend>－</template>
            <template #append>元</template>
          </el-input>
          <el-input
            v-if="ruleForm.event_type === 3"
            v-model="ruleForm.event_value"
            placeholder="請輸入改價金額"
            style="width: calc(50% - 12.5px)"
            type="number"
          >
            <template #append>元</template>
          </el-input>
          <div v-if="ruleForm.event_type === 11" class="giveaway">
            <el-form-item class="form-item" prop="event_value_product_id">
              <template #label>
                <span class="label">商品</span>
              </template>
              <el-cascader
                v-model="ruleForm.event_value_product_id"
                :options="getProductTree"
                :show-all-levels="false"
                :props="productTreeProps"
                placeholder="請選擇或輸入品項名稱"
                clearable
                filterable
                style="width: 100%"
                @change="handleSelectProduct($event, 'giveaway')"
              />
            </el-form-item>
            <el-form-item class="form-item">
              <template #label>
                <span class="label">容量</span>
              </template>
              <el-select
                v-model="ruleForm.event_value_size"
                placeholder="請選擇容量"
                style="width: 100%"
                :disabled="!ruleForm.event_value_product_id"
              >
                <el-option label="不指定" value="" />
                <el-option v-for="item in getGiveawaySize" :key="item" :label="mapOptionsLabel(item)" :value="item" />
              </el-select>
            </el-form-item>

            <el-form-item class="form-item">
              <template #label>
                <span class="label">可替換商品</span>
              </template>
              <el-cascader
                v-model="selectProduct"
                :options="getProductTree"
                :show-all-levels="false"
                :props="productTreeProps"
                placeholder="請選擇或輸入品項名稱"
                clearable
                filterable
                style="width: 100%"
                :disabled="!ruleForm.event_value_product_id"
                @change="handleSelectProduct($event, 'replaceOptions')"
              />
            </el-form-item>
            <el-form-item class="form-item">
              <template #label>
                <span class="label">容量</span>
              </template>
              <el-select
                v-model="selectSize"
                placeholder="請選擇容量"
                style="width: 100%"
                :disabled="!ruleForm.event_value_product_id"
              >
                <el-option label="不指定" value="" />
                <el-option
                  v-for="item in getReplaceProductSize"
                  :key="item"
                  :label="mapOptionsLabel(item)"
                  :value="item"
                />
              </el-select>
            </el-form-item>

            <div class="event-rule-list" style="grid-area: 3/1/8/3">
              <el-button
                style="width: 100%; margin-bottom: 8px"
                :disabled="!selectProduct"
                @click="handleAddEventProduct('product')"
              >
                新增可替換商品
                <el-icon style="margin-left: 5px"><CirclePlus /></el-icon>
              </el-button>
              <el-table
                :data="ruleForm.event_value_replace_product_list"
                style="width: 100%"
                :empty-text="`請按「新增可替換商品」加入項目，至少新增一項`"
                class="event-product-table"
                header-cell-class-name="product-table-header"
              >
                <el-table-column prop="product_id" label="可替換商品">
                  <template #default="scope">
                    <span>{{ mapProductLabel(scope.row.product_id) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="size" label="容量" width="120">
                  <template #default="scope">
                    <span>{{ mapOptionsLabel(scope.row.size) || '不指定' }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="80">
                  <template #default="scope">
                    <el-button
                      link
                      type="danger"
                      size="small"
                      @click.prevent="handleDeleteEventProduct(scope.$index), '2'"
                    >
                      刪除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <!-- <el-cascader
              v-model="ruleForm.event_value_product_id"
              :options="getProductTree"
              :show-all-levels="false"
              :props="productTreeProps"
              placeholder="請選擇或輸入品項名稱"
              clearable
              filterable
              style="width: calc(50% - 12.5px); grid-area: 1/1/2/3"
              @change="handleSelectProduct($event, 'giveaway')"
            />
            <div style="width: 100%">
              <p style="font-size: 1.5rem; margin-bottom: 12px">容量</p>
              <el-select
                v-model="ruleForm.event_value_size"
                placeholder="請選擇容量"
                style="width: 100%"
                :disabled="!ruleForm.event_value_product_id"
              >
                <el-option label="不指定" value="" />
                <el-option v-for="item in getGiveawaySize" :key="item" :label="mapOptionsLabel(item)" :value="item" />
              </el-select>
            </div> -->
            <!-- <div style="width: 100%">
              <p style="font-size: 1.5rem; margin-bottom: 12px">可替換商品</p>
              <el-cascader
                v-model="ruleForm.event_value_replace_product_list"
                :options="getProductTree"
                :show-all-levels="false"
                :props="replaceTreeProps"
                placeholder="請選擇或輸入品項名稱"
                clearable
                filterable
                collapse-tags
                collapse-tags-tooltip
                style="width: 100%"
                :disabled="!ruleForm.event_value_product_id"
              />
            </div> -->
          </div>
          <el-input
            v-if="ruleForm.event_type === 12"
            v-model="ruleForm.event_value"
            placeholder="請輸入金額"
            style="width: calc(50% - 12.5px)"
            type="number"
          >
            <template #append>元</template>
          </el-input>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleSubmit(ruleFormRef)" style="margin-left: 25px">
          {{ cusProps.action === 'create' ? '新增' : '儲存' }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, defineAsyncComponent, watch, getCurrentInstance, reactive } from 'vue';
import { formatDateTime } from '@/utils/datetime';
import { useGetters, useActions, useMutations } from '@/utils/mapStore';
import {
  mapOptionsLabel,
  mapProductLabel,
  mapCategoryLabel,
  time_period_type_options,
  available_shop_type_options,
  area_options,
  source_options,
  take_away_options,
  event_rule_category_options,
  event_rule_type_options,
  event_range_options,
  event_type_options,
  event_type_options_giveaway,
} from '@/utils/define';
export default {
  name: 'auto-dialog',
  components: {
    UploadFile: defineAsyncComponent(() => import('@/components/common/UploadFile.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const submit = inject('submit');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    const datetimeRange = ref(null);
    const selectEventRuleId = ref(null);
    const selectEventRuleSize = ref('');
    const selectProduct = ref('');
    const selectSize = ref('');
    //取得各式選項
    const { getCategoryList, getProductTree, getProductList } = useGetters('common', [
      'getCategoryList',
      'getProductTree',
      'getProductList',
    ]);
    const { getProductSize, getGiveawaySize, getReplaceProductSize } = useGetters('autoDiscount', [
      'getProductSize',
      'getGiveawaySize',
      'getReplaceProductSize',
    ]);
    const {
      SET_PRODUCT_SIZE,
      SET_GIVEAWAY_SIZE,
      SET_REPLACE_PRODUCT_SIZE,
      CLEAR_OPTIONS,
      CLEAR_REPLACE_PRODUCT_OPTION,
    } = useMutations('autoDiscount', [
      'SET_PRODUCT_SIZE',
      'SET_GIVEAWAY_SIZE',
      'CLEAR_OPTIONS',
      'SET_REPLACE_PRODUCT_SIZE',
      'CLEAR_REPLACE_PRODUCT_OPTION',
    ]);
    const { doGetProductOption, doGetGiveawayOption, doGetReplaceProductOption } = useActions('autoDiscount', [
      'doGetProductOption',
      'doGetGiveawayOption',
      'doGetReplaceProductOption',
    ]);
    //商品樹props設定
    const productTreeProps = {
      emitPath: false,
      value: 'id',
      label: 'name',
      children: 'product_list',
    };
    const replaceTreeProps = {
      emitPath: false,
      value: 'id',
      label: 'name',
      children: 'product_list',
      multiple: true,
    };
    //--------------------------------------------------------------------
    //設定初始值
    if (dialogVisible) {
      if (cusProps.init) {
        ruleForm.value = JSON.parse(JSON.stringify(cusProps.init));
        //日期格式
        if (ruleForm.value.time_period_type === 2) {
          datetimeRange.value = [];
          ruleForm.value.available_start_time = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.available_start_time);
          ruleForm.value.available_end_time = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.available_end_time);
          datetimeRange.value.push(ruleForm.value.available_start_time);
          datetimeRange.value.push(ruleForm.value.available_end_time);
        }
        //折數需要轉換成個位數(前端使用個位數、後端使用十位數)
        if (ruleForm.value.event_type === 1) ruleForm.value.event_value = Number(ruleForm.value.event_value) / 10;
        //取得贈品的容量list
        if (ruleForm.value.event_type === 11) {
          doGetGiveawayOption(ruleForm.value.event_value_product_id);
        }
      } else
        ruleForm.value = {
          event_id: null,
          name: '',
          remark: '',
          time_period_type: 1,
          available_start_time: null,
          available_end_time: null,
          available_shop_type: 1,
          available_shop_value_array: [],
          available_shop_file_url: null,
          available_shop_file_name: null,
          event_rule_source_platform: [],
          event_rule_take_away_method: [],
          event_rule_category: 1, //折扣類型(全部/類別/商品)
          event_rule_value: [], //折扣類別/商品list(折扣類型為類別或商品時使用)
          event_rule_type: 1, //折扣規則(任選/組合/滿額/整單)
          event_rule_amount: '', //任選的數量 或 滿額的金額
          event_range: 1, //折扣範圍
          event_type: 1, //折扣內容的類型
          event_value: '', //折扣內容
          event_value_product_id: '', //折扣贈品id(折扣內容類型為贈品時使用)
          event_value_size: '', //折扣贈品容量(折扣內容類型為贈品時使用)
          event_value_replace_product_list: [], //折扣贈品替換商品(折扣內容類型為贈品時使用)
        };
      selectProduct.value = '';
      selectSize.value = '';
    }
    watch(dialogVisible, () => {
      CLEAR_OPTIONS();
    });
    //----------------------------------------------------------------------
    //處理來源跟類型的全選
    const handleSelectSource = (val) => {
      if (val.includes('all')) {
        ruleForm.value.event_rule_source_platform = [];
        source_options.forEach((e) => {
          ruleForm.value.event_rule_source_platform.push(e.value);
        });
      } else if (val.includes('none')) {
        ruleForm.value.event_rule_source_platform = [];
      }
    };
    const handleSelectMethod = (val) => {
      if (val.includes('all')) {
        ruleForm.value.event_rule_take_away_method = [];
        take_away_options.forEach((e) => {
          ruleForm.value.event_rule_take_away_method.push(e.value);
        });
      } else if (val.includes('none')) {
        ruleForm.value.event_rule_take_away_method = [];
      }
    };
    const checkSelectAll = (select_arr, option_arr) => {
      if (select_arr.length === option_arr.length) return true;
      else return false;
    };
    //----------------------------------------------------------------------
    //選擇商品並取得容量
    const handleSelectProduct = (id, type) => {
      switch (type) {
        case 'giveaway':
          ruleForm.value.event_value_size = '';
          if (id) doGetGiveawayOption(id);
          else SET_GIVEAWAY_SIZE([]);
          break;
        case 'replaceOptions':
          selectSize.value = '';
          if (id) doGetReplaceProductOption(id);
          else SET_REPLACE_PRODUCT_SIZE([]);
          break;
        default:
          selectEventRuleSize.value = '';
          if (id) doGetProductOption({ id, type });
          else SET_PRODUCT_SIZE([]);
          break;
      }
    };
    //選擇類別或商品加入列表
    const handleAddEventProduct = (type) => {
      if (type === 'category') {
        ruleForm.value.event_rule_value.push({ id: selectEventRuleId.value, size: selectEventRuleSize.value });
        selectEventRuleId.value = null;
        selectEventRuleSize.value = '';
      } else {
        ruleForm.value.event_value_replace_product_list.push({
          product_id: selectProduct.value,
          size: selectSize.value,
        });
        selectProduct.value = '';
        selectSize.value = '';
        CLEAR_REPLACE_PRODUCT_OPTION();
      }
    };
    //從列表中刪除商品或類別
    const handleDeleteEventProduct = (index, type) => {
      if (type === '1') {
        ruleForm.value.event_rule_value.splice(index, 1);
      } else {
        ruleForm.value.event_value_replace_product_list.splice(index, 1);
      }
    };
    //切換折扣商品
    const handleChangeCategory = (val) => {
      if (ruleForm.value.event_rule_category === val) return;
      if (ruleForm.value.event_rule_value?.length === 0) reset();
      else {
        proxy
          .$confirm(`切換後目前的表單將會被清空，確定要切換嗎？`, '確認', {
            confirmButtonText: '切換',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(() => reset());
      }
      function reset() {
        ruleForm.value.event_rule_category = val;
        selectEventRuleId.value = null;
        selectEventRuleSize.value = '';
        SET_PRODUCT_SIZE([]);
        ruleForm.value.event_rule_value = [];
      }
    };
    //--------------------------------------------------------------------
    //radio切換-賦予初始值、清除錯誤提示
    const clearItemValidate = (form, val) => {
      form.clearValidate([val]);
    };
    //切換門市
    watch(
      () => [
        ruleForm.value.available_shop_type,
        ruleForm.value.available_shop_value_array,
        ruleForm.value.available_shop_file_url,
      ],
      () => {
        clearItemValidate(ruleFormRef.value, 'available_shop_type');
      }
    );
    //切換折扣商品
    watch(
      () => ruleForm.value.event_rule_category,
      () => {
        clearItemValidate(ruleFormRef.value, 'event_rule_category');
      }
    );
    //切換折扣方式
    watch(
      () => ruleForm.value.event_rule_type,
      () => {
        if (ruleForm.value.event_rule_type === cusProps.init?.event_rule_type) {
          //回復初始值
          ruleForm.value.event_range = cusProps.init?.event_range;
          ruleForm.value.event_type = cusProps.init?.event_type;
          ruleForm.value.event_rule_amount = cusProps.init?.event_rule_amount;
          ruleForm.value.event_value = cusProps.init?.event_value;
          ruleForm.value.event_value_product_id = cusProps.init?.event_value_product_id;
          ruleForm.value.event_value_size = cusProps.init?.event_value_size;
          ruleForm.value.event_value_replace_product_list = cusProps.init?.event_value_replace_product_list;
          //折數需要轉換成個位數(前端使用個位數、後端使用十位數)
          if (ruleForm.value.event_type === 1) ruleForm.value.event_value = Number(ruleForm.value.event_value) / 10;
        } else {
          //清空
          ruleForm.value.event_range = 1;
          ruleForm.value.event_type = 1;
          ruleForm.value.event_rule_amount = '';
          ruleForm.value.event_value = '';
          ruleForm.value.event_value_product_id = '';
          ruleForm.value.event_value_size = '';
          ruleForm.value.event_value_replace_product_list = [];
          selectProduct.value = '';
          selectSize.value = '';
        }
        clearItemValidate(ruleFormRef.value, 'event_rule_amount');
        clearItemValidate(ruleFormRef.value, 'event_type');
      }
    );
    watch(
      () => ruleForm.value.event_range,
      () => {
        if (
          ruleForm.value.event_range === cusProps.init?.event_range &&
          ruleForm.value.event_rule_type === cusProps.init?.event_rule_type
        ) {
          //回復初始值
          ruleForm.value.event_type = cusProps.init?.event_type;
          ruleForm.value.event_value = cusProps.init?.event_value;
          //折數需要轉換成個位數(前端使用個位數、後端使用十位數)
          if (ruleForm.value.event_type === 1) ruleForm.value.event_value = Number(ruleForm.value.event_value) / 10;
        } else {
          //清空
          ruleForm.value.event_type = 1;
          ruleForm.value.event_value = '';
        }
        clearItemValidate(ruleFormRef.value, 'event_type');
      }
    );
    watch(
      () => ruleForm.value.event_type,
      () => {
        if (
          ruleForm.value.event_type === cusProps.init?.event_type &&
          ruleForm.value.event_rule_type === cusProps.init?.event_rule_type &&
          ruleForm.value.event_range === cusProps.init?.event_range
        ) {
          //回復初始值
          ruleForm.value.event_value = cusProps.init?.event_value;
          ruleForm.value.event_value_product_id = cusProps.init?.event_value_product_id;
          ruleForm.value.event_value_size = cusProps.init?.event_value_size;
          ruleForm.value.event_value_replace_product_list = cusProps.init?.event_value_replace_product_list;
          //折數需要轉換成個位數(前端使用個位數、後端使用十位數)
          if (ruleForm.value.event_type === 1) ruleForm.value.event_value = Number(ruleForm.value.event_value) / 10;
        } else {
          //清空
          ruleForm.value.event_value = '';
          ruleForm.value.event_value_product_id = '';
          ruleForm.value.event_value_size = '';
          ruleForm.value.event_value_replace_product_list = [];
          selectProduct.value = '';
          selectSize.value = '';
        }
        clearItemValidate(ruleFormRef.value, 'event_type');
      }
    );
    watch(
      ruleForm,
      () => {
        if (ruleForm.value.event_rule_value?.length > 0) clearItemValidate(ruleFormRef.value, 'event_rule_category');
        if (ruleForm.value.event_value) clearItemValidate(ruleFormRef.value, 'event_type');
        if (ruleForm.value.event_value_product_id) clearItemValidate(ruleFormRef.value, 'event_type');
      },
      { deep: true }
    );
    //--------------------------------------------------------------------
    //日期相關
    watch(
      () => datetimeRange.value,
      () => {
        if (!datetimeRange.value) {
          ruleForm.value.available_start_time = null;
          ruleForm.value.available_end_time = null;
        } else if (datetimeRange.value.length > 0) {
          ruleForm.value.available_start_time = datetimeRange.value[0];
          ruleForm.value.available_end_time = datetimeRange.value[1];
        }
      }
    );
    const defaultTime = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 0)];
    //--------------------------------------------------------------------
    //驗證
    const validateTime = (rule, value, callback) => {
      if (ruleForm.value.time_period_type === 1) callback();
      else {
        if (!datetimeRange.value) return callback(new Error('請選擇日期'));
        else callback();
      }
    };
    const validateShop = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.available_shop_value_array?.length === 0) return callback(new Error('請選擇區域'));
          else callback();
          break;
        case 3:
          if (!ruleForm.value.available_shop_file_name || !ruleForm.value.available_shop_file_url)
            return callback(new Error('請選擇檔案'));
          else callback();
          break;
      }
    };
    const validateCategory = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        default:
          if (ruleForm.value.event_rule_value?.length === 0)
            return callback(new Error(`請至少加入一個${value === 2 ? '類別' : '商品'}`));
          else callback();
          break;
      }
    };
    const validateAmount = (rule, value, callback) => {
      if (!value) return callback(new Error('不能為空值'));
      else if (Number(value) < 0) return callback(new Error('不能為負數'));
      else callback();
    };
    const validateType = (rule, value, callback) => {
      const regex = /^(\d{1})(\.\d{1})?$/;
      switch (value) {
        case 11:
          if (!ruleForm.value.event_value_product_id) return callback(new Error('請選擇一個商品'));
          else callback();
          break;
        default:
          if (!ruleForm.value.event_value && !ruleForm.value.event_value !== 0)
            return callback(new Error('不能為空值'));
          else if (Number(ruleForm.value.event_value) < 0) return callback(new Error('不能為負數'));
          else if (value === 1 && Number(ruleForm.value.event_value) >= 10) return callback(new Error('請輸入個位數'));
          else if (value === 1 && !regex.test(ruleForm.value.event_value))
            return callback(new Error('小數點只能輸入一位'));
          else callback();
          break;
      }
    };
    const rules = reactive({
      name: [{ required: true, message: '請輸入活動名稱', trigger: 'blur' }],
      time_period_type: [{ validator: validateTime, trigger: 'blur' }],
      available_shop_type: [{ validator: validateShop, trigger: 'blur' }],
      event_rule_source_platform: [{ required: true, message: '請選擇訂單來源', trigger: 'blur' }],
      event_rule_take_away_method: [{ required: true, message: '請選擇訂單類型', trigger: 'blur' }],
      event_rule_category: [{ validator: validateCategory, trigger: 'blur' }],
      event_rule_amount: [{ validator: validateAmount, trigger: 'blur' }],
      event_type: [{ validator: validateType, trigger: 'blur' }],
    });
    //--------------------------------------------------------------------
    //送出
    const doSaveAutoDiscount = useActions('autoDiscount', ['doSaveAutoDiscount']);
    const handleSubmit = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        //折數需要轉換成十位數(前端使用個位數、後端使用十位數)
        if (ruleForm.value.event_type === 1) ruleForm.value.event_value = Number(ruleForm.value.event_value) * 10;
        ruleForm.value.short_name = ruleForm.value.name;
        await doSaveAutoDiscount(ruleForm.value);
        proxy.$message({
          type: 'success',
          message: `${cusProps.action === 'create' ? '新增' : '修改'}成功`,
        });
        submit({ action: cusProps.action, event: ruleForm.value });
      } catch (e) {
        e;
      }
    };
    return {
      mapOptionsLabel,
      mapProductLabel,
      mapCategoryLabel,
      time_period_type_options,
      available_shop_type_options,
      area_options,
      source_options,
      take_away_options,
      event_rule_category_options,
      event_rule_type_options,
      event_range_options,
      event_type_options,
      event_type_options_giveaway,
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      datetimeRange,
      selectEventRuleId,
      selectEventRuleSize,
      getCategoryList,
      getProductTree,
      getProductList,
      getProductSize,
      getGiveawaySize,
      getReplaceProductSize,
      productTreeProps,
      replaceTreeProps,
      clearItemValidate,
      defaultTime,
      handleSelectSource,
      handleSelectMethod,
      checkSelectAll,
      handleSelectProduct,
      handleAddEventProduct,
      handleDeleteEventProduct,
      handleChangeCategory,
      rules,
      handleSubmit,
      selectProduct,
      selectSize,
    };
  },
};
</script>
<style lang="scss" scoped>
.discount-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.title {
  margin-top: 20px;
  span {
    color: $color-primary;
    font-size: 1.6rem;
  }
}
.dialog-footer {
  @include full-button;
}
.el-divider--horizontal {
  margin: 4px 0 24px;
}
.giveaway {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
}
.event-rule-list {
  width: 100%;
  margin: 0 auto 30px;
  :deep(.event-product-table) {
    border: 1px solid $el-border-color-lighter !important;
    border-bottom: none !important;
    .product-table-header {
      font-weight: 500;
      color: $color-text-light;
      background-color: $color-background !important;
    }
  }
}
.product-list {
  width: 100%;
  margin: 0 auto 30px;
  :deep(.product-table) {
    border: 1px solid $el-border-color-lighter !important;
    border-bottom: none !important;
    .product-table-header {
      font-weight: 500;
      color: $color-text-light;
      background-color: $color-background !important;
    }
  }
}
</style>
